const questions = [
    // {
    //     title: '请从以下选项中选择枪十七的经典推文。',
    //     options: [
    //         'Web3市值前100项目大盘点',
    //         'BTC庄家日记',
    //         '凉兮大战福禄寿始末',
    //     ],
    //     answer: 1
    // },
    // {
    //     title: '枪十七，推特中文圈知名KOL，2023上半年对多个项目代币的走势进行了精准预测，下列那项不属于其经典战绩？',
    //     options: [
    //         'AIDOGE四天100倍',
    //         '推特中文区OP价值发现第一人',
    //         'SSV今年三月后震荡下跌',
    //     ],
    //     answer: 0
    // },
    {
        title: '枪十七在「五年炒币，三年模拟」：如何评估一个币？这场Space中提到以下哪项对Token的评估最为重要？',
        options: [
            '团队，大佬的项目更容易成功',
            '营销，会cx的项目更容易成功',
            '资金，币价只和资金的进出有关',
        ],
        answer: 2
    },
    {
        title: '下列哪项不是枪十七在Space中提出的交易思考模式。',
        options: [
            '动态博弈',
            '迭代定义',
            '价值投资',
        ],
        answer: 2
    },
    // {
    //     title: '关注了枪总一段时间的朋友都会知道两个数字，这两个数字可以大大提升你的幸福指数，这两个数字是？',
    //     options: [
    //         '4.0，5.0',
    //         '1.7，1.8',
    //         '2.3，2.4',
    //     ],
    //     answer: 2
    // },
    {
        title: '枪总在「信息茧房」那期Space中提到，即使是大佬，做事也容易局限于自己的认知，形成路径依赖。现在假设你得知了一家VC投资了一个NFT项目，你应该？',
        options: [
            '了解一下这家VC是否擅长NFT投资',
            '梭哈，VC投资的项目一定会涨',
            '抢先配置适当的仓位，提前埋伏',
        ],
        answer: 0
    },
    {
        title: '枪总在推文中曾数次提到「新公链牛」，新公链牛的成因是？',
        options: [
            '新公链的技术可能更先进',
            '以太坊不是昂撒人的亲儿子',
            '新公链玩的人少，车轻',
        ],
        answer: 1
    },
    {
        title: '2023年10月16日伴随着ETF通过的假新闻，BTC的价格发生了「插针」，这次插针的主要目的是？',
        options: [
            '多空双爆',
            '庄家拉高出货',
            '测盘',
        ],
        answer: 2
    },
    {
        title: '关于"外延资金低"和"拉盘"二者的充要关系，下列说法正确的是？',
        options: [
            '外延资金低一定会拉盘',
            '拉盘说明外延资金低',
            '外延资金低是拉盘的充要条件',
        ],
        answer: 1
    },
    {
        title: '23年11月10日的Space中曾用黄金类比BTC。对于庄家来说，（ ）之于BTC类似于"黄金首饰"之于"黄金"。',
        options: [
            '合约',
            '铭文',
            '现货杠杆',
        ],
        answer: 1
    }
]


export default questions
